// ReusableAutoComplete.js
import React from 'react';
import Box from '../box';
import Typography from '../typography';
import Button from '../core/button';
import { useNavigate } from 'react-router-dom';

function Slides({ navKey, src, title, btn, btnDisabled, ...restProps }) {
  const navigate = useNavigate();

  return (
    <div className="slide-content">
      <Box display="flex" alignItems="center">
        <Box
          position="absolute"
          sx={{
            width: '50%',
            paddingLeft: { xl: '60px', md: '24px', xs: '12px' },
          }}
        >
          <Typography
            variant="h1"
            lineHeight={1.3}
            color="#000"
            textTransform="uppercase"
            fontWeight="bold"
          >
            {title}
          </Typography>
          <Button
            color="secondary"
            sx={{ marginTop: { md: '2rem', xs: '0.5rem' } }}
            className="btn-slide"
            disabled={btnDisabled}
            onClick={() => {
              navigate(navKey);
            }}
          >
            {btn}
          </Button>
        </Box>
        <img src={src} alt="banner-img" className="img-fluid" />
      </Box>
    </div>
  );
}

export default Slides;
