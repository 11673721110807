import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import Button from '../../../components/core/button';
import CloseIcon from '@mui/icons-material/Close';

function AdvertisementPopup({ handleClose, showPopup, type }) {
  return (
    <Dialog
      open={showPopup}
      onClose={handleClose}
      sx={{ width: { xs: '100%' } }}
      fullWidth={true}
      padding={0.5}
      className="howitwork-dialog"
    >
      <DialogTitle id="responsive-dialog-title" padding={1}>
        <Grid container>
          <Grid item xs={10} sm={10}>
            <Typography variant="h4" fontWeight="bold" color="#4F8D7D">
              How it works
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} textAlign="right">
            <Button variant="text" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div className="how-it-works">
          {type === 'merchant' ? (
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/yUYUXUZsJbM?si=TUfZV5fWqsBdKC7Z"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          ) : (
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/hJIFMXx0Qac?si=YaGy5TE3-tRzHCn7"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AdvertisementPopup;
