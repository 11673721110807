import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import Button from '../../../components/core/button';

function Help({ showHelpPopup, handleClose }) {
  return (
    <Dialog open={showHelpPopup} onClose={handleClose}>
      <DialogTitle id="responsive-dialog-title">
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h4" fontWeight="bold" color="#4F8D7D">
              Help
            </Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <Button variant="h6" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={12}>
            Please email your questions to help@savyomo.com
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default Help;
