import { Email } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/images/savyomo-logo.svg';
import MerchantPrivacyPolicy from '../../module/auth/components/MerchantPrivacyPolicy';
import MerchantTermsConditions from '../../module/auth/components/MerchantTermsConditions';
import PrivacyPolicy from '../../module/auth/components/PrivacyPolicy';
import TermsConditions from '../../module/auth/components/TermsConditions';
import ROUTES from '../../utils/constant/routePaths';
import Box from '../box';
import Col from '../col';
import Row from '../row';
import Button from '../core/button';
import Help from '../../module/auth/components/Help';

function Footer() {
  const navigate = useNavigate();

  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [showPrivacyPopup, setShowPrivacyPopup] = useState(false);
  const [showHelpPopup, setShowHelpPopup] = useState(false);

  const userInfo = useSelector((state) => state.auth.userData);

  return (
    <>
      {userInfo?.role === 'provider' ? (
        <>
          <MerchantPrivacyPolicy
            showPrivacyPolicyPopup={showPrivacyPopup}
            handleClose={() => {
              setShowPrivacyPopup(false);
            }}
          />
          <MerchantTermsConditions
            showTermsPopup={showTermsPopup}
            handleClose={() => {
              setShowTermsPopup(false);
            }}
          />
        </>
      ) : (
        <>
          <PrivacyPolicy
            showPrivacyPolicyPopup={showPrivacyPopup}
            handleClose={() => {
              setShowPrivacyPopup(false);
            }}
          />
          <TermsConditions
            showTermsPopup={showTermsPopup}
            handleClose={() => {
              setShowTermsPopup(false);
            }}
          />
        </>
      )}

      <Help
        showHelpPopup={showHelpPopup}
        handleClose={() => {
          setShowHelpPopup(false);
        }}
      />

      <Box
        bgcolor="#0A0F17"
        color="#b9b9b9"
        sx={{ p: { xs: 2, md: 3, lg: 3, xl: 8 }, pb: { xl: 5 }, pt: { lg: 4 } }}
        paddingBottom={4}
      >
        <Row noSpace sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
          <Col
            lg={3}
            md={4}
            sm={4}
            xs={12}
            sx={{ textAlign: { xs: 'center', sm: 'left' } }}
          >
            <Logo
              width={240}
              onClick={() => {
                if (userInfo?.role === 'consumer') {
                  navigate(ROUTES.USER.DASHBOARD);
                }
              }}
              style={{ cursor: 'pointer' }}
            />
            <Typography
              variant="h6"
              fontWeight="300"
              width={{ md: 280, sm: 240 }}
              marginTop={3}
            >
              With our incredible & flexible savings opportunities, you can keep
              more of your hard-earned cash in your pocket.
            </Typography>
          </Col>
          <Col lg={3} md={2} sm={3} xs={12} sx={{ pt: { xs: 3 } }}>
            <Typography variant="h4">About</Typography>
            <List>
              <ListItem className="p-0">
                <ListItemButton className="p-0">
                  <ListItemText
                    primary="About us"
                    sx={{ textAlign: { xs: 'center', sm: 'left' } }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem className="p-0">
                <ListItemButton className="p-0">
                  <ListItemText
                    primary="Careers"
                    sx={{ textAlign: { xs: 'center', sm: 'left' } }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem className="p-0">
                <ListItemButton className="p-0">
                  <ListItemText
                    primary="Blog"
                    sx={{ textAlign: { xs: 'center', sm: 'left' } }}
                  />
                </ListItemButton>
              </ListItem>
              {/* <ListItem
                className="p-0"
                onClick={() => {
                  setShowPrivacyPopup(true);
                }}
              >
                <ListItemButton className="p-0">
                  <ListItemText
                    primary="Privacy Policy"
                    sx={{ textAlign: { xs: 'center', sm: 'left' } }}
                  />
                </ListItemButton>
              </ListItem> */}
              {/* <ListItem
                className="p-0"
                onClick={() => {
                  setShowTermsPopup(true);
                }}
              >
                <ListItemButton className="p-0">
                  <ListItemText
                    primary="Terms & Condition"
                    sx={{ textAlign: { xs: 'center', sm: 'left' } }}
                  />
                </ListItemButton>
              </ListItem> */}
              <ListItem className="p-0">
                <ListItemButton className="p-0">
                  <ListItemText
                    primary="Help"
                    sx={{ textAlign: { xs: 'center', sm: 'left' } }}
                    onClick={() => {
                      setShowHelpPopup(true);
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Col>

          <Col lg={3} md={2} sm={2} xs={12} sx={{ pt: { xs: 3 } }}>
            <Typography variant="h4">Categories</Typography>
            <List>
              <ListItem className="p-0">
                <ListItemButton
                  className="p-0"
                  onClick={() => {
                    userInfo.role === 'consumer' &&
                      navigate(ROUTES.USER.RESTAURANTS.CUISINE_PLAN);
                  }}
                >
                  <ListItemText
                    primary="Restaurants"
                    sx={{ textAlign: { xs: 'center', sm: 'left' } }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem className="p-0">
                <ListItemButton
                  className="p-0"
                  onClick={() => {
                    userInfo.role === 'consumer' &&
                      navigate(ROUTES.USER.AUTO_MOTIVE.AUTO_SERVICE_PLAN);
                  }}
                >
                  <ListItemText
                    primary="Automotive Services"
                    sx={{ textAlign: { xs: 'center', sm: 'left' } }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem className="p-0">
                <ListItemButton className="p-0">
                  <ListItemText
                    primary="Air Tickets"
                    sx={{ textAlign: { xs: 'center', sm: 'left' } }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem className="p-0">
                <ListItemButton className="p-0">
                  <ListItemText
                    primary="Hotel & Motels"
                    sx={{ textAlign: { xs: 'center', sm: 'left' } }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Col>

          <Col lg={3} md={2} sm={3} xs={12} sx={{ pt: { xs: 3 } }}>
            <Typography variant="h4">Contact</Typography>
            <List>
              <ListItem className="p-0">
                <Box color="#4D8E7E" paddingRight={1}>
                  {/* <LocationOn /> */}
                </Box>
                {/* <ListItemButton> */}
                {/* <ListItemText primary="2688 D StreetFlint, MI 48548" /> */}
                {/* </ListItemButton> */}
              </ListItem>
              <ListItem
                className="p-0"
                display={{ xs: 'block' }}
                sx={{ textAlign: { xs: 'center', sm: 'left' } }}
              >
                {/* <ListItemButton> */}
                <ListItemText
                  m={0}
                  primary={
                    <Button
                      variant="text"
                      size="small"
                      sx={{
                        textTransform: 'titlecase',
                        p: 0,
                        // textTransform: 'lowercase',
                      }}
                      href="mailto:info@savyomo.com"
                    >
                      <Box
                        color="#4D8E7E"
                        sx={{
                          textAlign: { xs: 'center', sm: 'left' },
                        }}
                        paddingRight={1}
                      >
                        <Email />
                      </Box>
                      <Box paddingBottom={1}>help@savyomo.com</Box>
                    </Button>
                  }
                />
                {/* </ListItemButton> */}
              </ListItem>
            </List>
          </Col>
        </Row>
        <Box sx={{ borderTop: 1, borderColor: '#3B3F45', marginTop: 5 }}>
          <Typography variant="h6" paddingTop={2}>
            © 2025 Savyomo.com. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default Footer;
