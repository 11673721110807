import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';

import Button from '../../../components/core/button';

function TermsConditions({ showTermsPopup, handleClose }) {
  return (
    <Dialog open={showTermsPopup} onClose={handleClose}>
      <DialogTitle id="responsive-dialog-title">
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h4" fontWeight="bold" color="#4F8D7D">
              Terms Of Use
            </Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <Button variant="text" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5" fontWeight="bold" color="#4F8D7D">
              1. Introduction
            </Typography>
            <Typography mb={1}>
              Welcome to [savyomo.com]! The following terms and conditions
              (&quot;Terms&quot;) regulate the utilization of our website
              situated at [www.savyomo.com or savyomo.com] (referred to as the
              &quot;Website&quot;). Your access or use of the Website implies
              your acceptance of these Terms.
            </Typography>
            <Typography>
              We kindly ask that you review and understand these terms
              thoroughly.
            </Typography>
            <Typography fontWeight={'bold'} mb={1}>
              These Terms are between you and Savyomo LLC.
            </Typography>
            <Typography my={1}>Effective as of: March 28, 2024</Typography>
            <Typography mb={1}>
              Should you disagree with any portion of the Terms, you are not
              permitted to access or use the Website and refrain from utilizing
              any Savyomo services, or acquiring any promotional codes,
              products, or services provided through the site. Thank you.
            </Typography>
            <Typography mb={1}>
              Furthermore, to utilize the Savyomo Service and access any
              Content, you are required to confirm that you are a resident of
              the United States, and that all registration and account
              information submitted to Savyomo is truthful, precise, and
              comprehensive. It is imperative that you agree to maintain the
              accuracy of this information at all times. You agree to pay any
              applicable fees associated with using the Website.
            </Typography>
            <Typography variant="h5" fontWeight="bold" color="#4F8D7D">
              2. Ownership
            </Typography>
            <Typography mb={1}>
              The Website is the exclusive property of SAVYOMO LLC (“Savyomo”
              “we” or “us” or “our” or “The Website” or “The Company”), and its
              operations are overseen by us. All content featured on the
              Website, including but not limited to text, graphics, logos,
              images, and software, is owned by us. Such content is safeguarded
              by intellectual property laws.
            </Typography>

            <Typography variant="h5" fontWeight="bold" color="#4F8D7D">
              3. User Conduct
            </Typography>
            <Typography mb={1}>
              You are required to use the Website solely for legal purposes and
              in a manner that respects the rights of others. Activities that
              are not allowed include but are not limited to:
              <ul style={{ paddingTop: '1rem', paddingInlineStart: '2rem' }}>
                <li>Breaching any laws or regulations that are applicable.</li>
                <li>
                  Engaging in illegal activities through the use of website
                  content.
                </li>
                <li>
                  Tampering with the functionality of the Website or its
                  servers, as well as any networks connected to the Website.
                </li>
              </ul>
            </Typography>
            <Typography variant="h5" fontWeight="bold" color="#4F8D7D">
              4. Disclaimers
            </Typography>
            <Typography mb={1}>
              The Website and all content provided on it are offered in their
              current state without any guarantees or warranties, both explicit
              and implicit, such as merchantability, suitability for specific
              purposes, and non-infringement. We cannot guarantee or warranty
              the accuracy, reliability, or absence of errors in the content.
            </Typography>

            <Typography variant="h5" fontWeight="bold" color="#4F8D7D">
              5. Age and eligibility requirements
            </Typography>
            <Typography mb={1}>
              By utilizing the Savyomo website, you confirm that you are of
              legal age (18 years or older) to agree to these terms. If you are
              under 18 years old, you must be at least 13 years old and have
              received permission from a parent or guardian to agree to these
              terms.
            </Typography>

            <Typography variant="h5" fontWeight="bold" color="#4F8D7D">
              6. Limitation of Liability
            </Typography>
            <Typography mb={1}>
              We are not responsible/liable for any damages that may occur as a
              result of your use of the website, including direct, indirect,
              incidental, consequential, and punitive damages. You are
              accountable for all interactions with Merchants. You release
              Savyomo from any claims or liability.
            </Typography>

            <Typography variant="h5" fontWeight="bold" color="#4F8D7D">
              7. Termination
            </Typography>
            <Typography mb={1}>
              We reserve the right to suspend or terminate your account at our
              discretion, without prior notice, due to any violation of our
              terms and conditions or suspected instances of fraudulent or
              abusive behavior.
            </Typography>

            <Typography variant="h5" fontWeight="bold" color="#4F8D7D">
              8. Dispute Resolution
            </Typography>
            <Typography mb={1}>
              Any dispute arising out of or relating to these Terms will be
              settled through binding arbitration in accordance with the rules
              of the American Arbitration Association. The arbitration shall be
              held in [Raleigh, NC].
            </Typography>

            <Typography variant="h5" fontWeight="bold" color="#4F8D7D">
              9. Changes to the Terms
            </Typography>
            <Typography mb={1}>
              We retain the right to make changes to these Terms without prior
              notice. We encourage you to review this page periodically to stay
              informed of any updates.
            </Typography>

            <Typography variant="h5" fontWeight="bold" color="#4F8D7D">
              10. Entire Agreement
            </Typography>
            <Typography mb={1}>
              These terms and conditions, together with our Privacy Policy,
              constitute the entire agreement between you and us in relation to
              your use of this website and supersede all previous agreements in
              respect of your use of this website.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default TermsConditions;
