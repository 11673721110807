import {
  CategoryOutlined,
  MarkChatUnreadOutlined,
  NoteAltOutlined,
  PanToolAltOutlined,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Automotive from '../../../assets/images/automotive-service.png';
import Restaurant from '../../../assets/images/restaurant.png';
import SavyomoAdvertise from '../../../assets/images/savyomo-advertise.png';
import SavyomoLogo from '../../../assets/images/savyomo-logo.svg';
import TicketBooking from '../../../assets/images/ticket-booking.png';
import Col from '../../../components/col';
import Container from '../../../components/container';
import Button from '../../../components/core/button';
import Footer from '../../../components/footer';
import NewsLetter from '../../../components/newsletter/newsletter';
import Row from '../../../components/row';
import Work from '../../../components/work/work';
import { CONSUMER } from '../../../utils/constant/misc';
import ROUTES from '../../../utils/constant/routePaths';
import { getAuthInfo } from '../../auth/services/authAPI';
import AdvertisementPopup from '../components/AdvertisementPopup';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SellSharpIcon from '@mui/icons-material/SellSharp';

const drawerWidth = 240;
const navItems = [
  { label: 'Consumer Sign In', path: ROUTES.USER.LOGIN },
  { label: 'Merchant Sign In', path: ROUTES.MERCHANT.LOGIN },
];

function HomePage({ window }) {
  const navigate = useNavigate();
  const [type, setType] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const { isAuthenticated, userRole, provider } = getAuthInfo();
    if (isAuthenticated) {
      navigate(
        userRole === CONSUMER
          ? ROUTES.USER.DASHBOARD
          : provider === 'restaurants'
            ? ROUTES.MERCHANT.RESTAURANT_ADVERTISEMENT
            : ROUTES.MERCHANT.AUTO_MOTIVE_ADVERTISEMENT
      );
    }
  }, [navigate]);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box className="m-1">
        <img
          src={SavyomoLogo}
          width={200}
          alt="savyomo-logo"
          style={{ cursor: 'pointer' }}
        />
      </Box>
      <Divider />
      <List>
        {navItems.map(({ label, path }) => (
          <ListItem key={path} disablePadding>
            <ListItemButton
              sx={{ textAlign: 'center' }}
              color="secondary"
              className="btn-login"
            >
              <Link to={path}>
                {path == ROUTES.USER.LOGIN ? (
                  <PersonOutlineIcon color="#98C2B9" />
                ) : (
                  <SellSharpIcon color="#98C2B9" />
                )}{' '}
                <ListItemText primary={label} sx={{ marginLeft: 1 }} />{' '}
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Container>
      <AdvertisementPopup
        showPopup={showPopup}
        handleClose={() => {
          setType('');
          setShowPopup(false);
        }}
        type={type}
      />
      <Box sx={{ fontFamily: 'alef' }}>
        <Box sx={{ display: 'flex' }}>
          <AppBar component="nav" color="white">
            <Toolbar
              sx={{
                margin: { xl: '0 70px 0 40px' },
                padding: { xs: '0 16px' },
              }}
            >
              <IconButton
                color="secondary"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'block' },
                  textAlign: { xs: 'center', sm: 'left' },
                }}
              >
                <img
                  src={SavyomoLogo}
                  alt="savyomo-logo"
                  width={240}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {navItems.map(({ label, path }) => (
                  <Button
                    key={path}
                    variant="text"
                    className="login-link"
                    sx={{ color: '#464646', fontSize: '1.2rem' }}
                    onClick={() => navigate(path)}
                  >
                    {label}
                  </Button>
                ))}
              </Box>
            </Toolbar>
          </AppBar>
          <nav>
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
          <Box component="main">
            <Toolbar />
          </Box>
        </Box>

        <Box bgcolor="#98C2B9" className="mb-4">
          <Row noSpace alignItems="center">
            <Col sm={7} lg={6} xl={6}>
              <Box
                className="banner-text"
                sx={{ padding: { xs: 2, lg: 3, xl: 8 } }}
              >
                <Typography
                  variant="h3"
                  color="#000"
                  fontWeight="900"
                  textTransform="uppercase"
                >
                  Are you looking for ways to save money?
                </Typography>
                <Typography variant="h5" color="#000" className="px-4">
                  Look no further! With our incredible & flexible savings
                  opportunities, you can keep more of your hard-earned cash in
                  your pocket.
                </Typography>
                {/* <Button
                  size="large"
                  color="secondary"
                  onClick={() => navigate(ROUTES.USER.SIGN_UP)}
                  sx={{ mr: '10px', fontSize: 16 }}
                >
                  Join Now
                </Button> */}

                <Button
                  size="large"
                  color="secondary"
                  onClick={() => {
                    setType('consumer');
                    setShowPopup(true);
                  }}
                  sx={{
                    fontSize: 16,
                    mr: '1rem',
                    marginBottom: { md: 0, xs: 2 },
                  }}
                >
                  Consumer - How It Works
                </Button>

                <Button
                  size="large"
                  color="secondary"
                  onClick={() => {
                    setType('merchant');
                    setShowPopup(true);
                  }}
                  sx={{ fontSize: 16 }}
                >
                  Merchant - How It Works
                </Button>
              </Box>
            </Col>
            <Col sm={5} xl={6}>
              <Box className="pt-2 banner-img" sx={{ width: { xl: 640 } }}>
                <img
                  src={SavyomoAdvertise}
                  alt="banner-img"
                  className="img-fluid"
                />
              </Box>
            </Col>
          </Row>
        </Box>

        <Box sx={{ padding: { xs: 2, xl: 8 } }}>
          <Row alignItems="center" className="pt-4">
            <Col sm={4} md={4} lg={4} xl={5}>
              <Box
                sx={{ width: { lg: 380, xl: 460 } }}
                className="mt-4 mx-auto"
              >
                <img src={Restaurant} alt="banner-img" className="img-fluid" />
              </Box>
            </Col>
            <Col sm={8} md={8} lg={8} xl={7}>
              <Box className="mt-4">
                <Typography variant="h4" fontWeight="bold">
                  Discover ways to save money while indulging in delicious meals
                  from your favorite restaurants.
                </Typography>
                <Typography variant="h6" className="mt-3">
                  Are you ready to embark on a heartwarming journey where your
                  taste buds are tantalized, and your wallet is delighted?
                  Indulge in the culinary wonders of your beloved restaurant
                  while savoring unparalleled savings.
                </Typography>
              </Box>
            </Col>
          </Row>
        </Box>

        <Box sx={{ padding: { xs: 2, xl: 8 } }}>
          <Row alignItems="center" className="pt-4" direction="row-reverse">
            <Col sm={4} md={4} lg={4} xl={5}>
              <Box
                sx={{ width: { lg: 380, xl: 460 } }}
                className="mt-4 mx-auto"
              >
                <img src={Automotive} alt="banner-img" className="img-fluid" />
              </Box>
            </Col>
            <Col sm={8} md={8} lg={8} xl={7}>
              <Box className="mt-4">
                <Typography variant="h4" fontWeight="bold">
                  Enhance your cost savings by taking advantage of our
                  automotive service offerings.
                </Typography>
                <Typography variant="h6" className="mt-3">
                  Whether you are looking for routine maintenance or more
                  intensive repairs, we have got you covered. Take advantage of
                  our flexible automotive service offerings today!
                </Typography>
              </Box>
            </Col>
          </Row>
        </Box>

        <Box sx={{ padding: { xs: 2, xl: 8 } }}>
          <Row alignItems="center" className="pt-4 pb-4">
            <Col sm={4} md={4} lg={4} xl={5}>
              <Box
                sx={{ width: { lg: 380, xl: 390 } }}
                className="mt-4 mx-auto"
              >
                <img
                  src={TicketBooking}
                  alt="banner-img"
                  className="img-fluid"
                />
              </Box>
            </Col>
            <Col sm={8} md={8} lg={8} xl={7}>
              <Box className="mt-4">
                <Typography variant="h4" fontWeight="bold">
                  Experience savings by booking reservations for air tickets or
                  hotel accommodations.
                </Typography>
                <Typography variant="h5" fontWeight="bold" color="#4D8E7E">
                  [Coming soon]
                </Typography>
                <Typography variant="h6" className="mt-3">
                  Hey there! Are you excited for your next vacation? Well, get
                  ready for some fantastic notifications – you can save big with
                  us! Take full advantage of the incredible discounts we have
                  waiting for you when you book your air tickets or hotel
                  accommodations.
                </Typography>
              </Box>
            </Col>
          </Row>
        </Box>

        <Box bgcolor="#E5EFED" sx={{ padding: { xs: 2, xl: 8 } }}>
          <Typography
            marginBottom={4}
            paddingTop={4}
            color="#000"
            fontWeight="bold"
            textAlign="center"
            variant="h3"
          >
            How it works
          </Typography>
          <Row>
            <Col sm={6}>
              <Work
                title="Join"
                subtitle="Sign up for a free account in less than 1 minute."
                icon={<PanToolAltOutlined fontSize="64px" />}
              />
              {/* <Box textAlign="center" marginBottom={6}>
                <Box color="#4D8E7E" bgcolor="#fff" width="120px" height="120px" margin="auto" alignItems="center" justifyContent="center" display="inline-flex" borderRadius="80px" fontSize="64px">
                  <PanToolAltOutlined fontSize="64px" />
                </Box>
                <Typography variant="h4" fontWeight="bold" marginTop={3}>
                  Join
                </Typography>
                <Typography variant="h6" marginTop={1}>
                  Sign up for a free account in less than 1 minute.
                </Typography>
              </Box> */}
            </Col>
            <Col sm={6}>
              <Work
                title="Choose Category"
                subtitle="Select a category from the available options, such as restaurants."
                icon={<CategoryOutlined fontSize="64px" />}
              />
              {/* <Box textAlign="center" marginBottom={6}>
                <Box color="#4D8E7E" bgcolor="#fff" width="120px" height="120px" margin="auto" alignItems="center" justifyContent="center" display="inline-flex" borderRadius="80px" fontSize="64px">
                  <CategoryOutlined fontSize="64px" />
                </Box>
                <Typography variant="h4" fontWeight="bold" marginTop={3}>
                  Choose Category
                </Typography>
                <Typography variant="h6" marginTop={1}>
                  Select a category from the available options, such as restaurants.
                </Typography>
              </Box> */}
            </Col>

            <Col sm={6}>
              <Work
                title="Create your own plan"
                subtitle="Develop your service plan according to the category you have selected."
                icon={<NoteAltOutlined fontSize="64px" />}
              />
              {/* <Box textAlign="center" marginBottom={6}>
                <Box color="#4D8E7E" bgcolor="#fff" width="120px" height="120px" margin="auto" alignItems="center" justifyContent="center" display="inline-flex" borderRadius="80px" fontSize="64px">
                  <NoteAltOutlined fontSize="64px" />
                </Box>
                <Typography variant="h4" fontWeight="bold" marginTop={3}>
                  Create your own plan
                </Typography>
                <Typography variant="h6" marginTop={1}>
                  Select a category from the available options, such as restaurants.
                </Typography>
              </Box> */}
            </Col>

            <Col sm={6}>
              <Work
                title="Get notified"
                subtitle="Get a sweet deal alert straight to your phone or through email!"
                icon={<MarkChatUnreadOutlined fontSize="64px" />}
              />
              {/* <Box textAlign="center" marginBottom={6}>
                <Box color="#4D8E7E" bgcolor="#fff" width="120px" height="120px" margin="auto" alignItems="center" justifyContent="center" display="inline-flex" borderRadius="80px" fontSize="64px">
                  <MarkChatUnreadOutlined fontSize="64px" />
                </Box>
                <Typography variant="h4" fontWeight="bold" marginTop={3}>
                  Get notified
                </Typography>
                <Typography variant="h6" marginTop={1}>
                  Get a sweet deal alert straight to your phone or through email!
                </Typography>
              </Box> */}
            </Col>
            <Col sm={6} />
          </Row>
        </Box>

        <Box sx={{ padding: { xs: 2, lg: 3, xl: 8 } }}>
          <Typography
            variant="h3"
            textAlign="center"
            fontWeight="bold"
            color="#4D8E7E"
          >
            NewsLetter
          </Typography>
          <NewsLetter />
        </Box>

        <Footer />
      </Box>
    </Container>
  );
}

export default HomePage;
