import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout';
import { getAuthInfo } from '../module/auth/services/authAPI';
import ROUTES from '../utils/constant/routePaths';
import { CONSUMER } from '../utils/constant/misc';

function PublicRoute() {
  const { isAuthenticated, userRole, provider } = getAuthInfo();
  const location = useLocation();
  const isPasswordRoute =
    location.pathname === ROUTES.USER.FORGOT_PASSWORD ||
    location.pathname.includes('set-password');

  if (isAuthenticated && !isPasswordRoute) {
    const redirectPath =
      userRole === CONSUMER
        ? ROUTES.USER.DASHBOARD
        : provider === 'restaurants'
          ? ROUTES.MERCHANT.RESTAURANT_ADVERTISEMENT
          : ROUTES.MERCHANT.AUTO_MOTIVE_ADVERTISEMENT;

    return <Navigate to={redirectPath} />;
  }

  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  );
}

export default PublicRoute;
