import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Button from '../../../components/core/button';
import { PasswordControl } from '../../../components/core/input/PasswordControl';
import ROUTES from '../../../utils/constant/routePaths';
import { clearAuthInfo } from '../../../utils/helper/authTokenStorage';
import { updatePassword, validateToken } from '../services/authAPI';
import { CONSUMER } from '../../../utils/constant/misc';

function SetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!token) {
      navigate(ROUTES.USER.LOGIN);
    } else {
      validateToken(token).then((res) => {
        if (res.status === 200) {
          setEmail(res.data.email);
        } else {
          navigate(ROUTES.USER.LOGIN);
        }
      });
    }
  }, [token]);

  const formSchema = useMemo(
    () =>
      yup.object({
        password: yup
          .string()
          .required('Please choose a password')
          .min(6, 'Password must be at least 6 characters long.'),
        confirmPassword: yup
          .string()
          .required('Confirm password is required')
          .test(
            'password-match',
            'Password and Confirm password must match',
            function (value) {
              return this.parent.password === value;
            }
          ),
      }),
    []
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const savePassword = (data) => {
    updatePassword({ email, password: data.password }).then((res) => {
      const { role } = res?.data || {};
      clearAuthInfo();
      navigate(role === CONSUMER ? ROUTES.USER.LOGIN : ROUTES.MERCHANT.LOGIN);
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(savePassword)}>
      <Grid container spacing={3} mt={2}>
        <Grid item xs={12} sm={12}>
          <PasswordControl
            id="password"
            label="Password"
            type="password"
            control={control}
            error={Boolean(errors.password)}
            rules={{ required: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <PasswordControl
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            control={control}
            error={Boolean(errors.confirmPassword)}
            rules={{ required: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button size="large" fullWidth type="submit">
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SetPassword;
