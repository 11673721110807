import isEmpty from 'lodash/isEmpty';
import store from '../../../store/configureStore';
import {
  clearAuthInfo,
  loadAuthInfo,
  saveAuthInfo,
} from '../../../utils/helper/authTokenStorage';
import { axiosAuthInstance } from '../../../utils/helper/axiosInstances';
import { updateUserData } from './authSlice';

export function getAuthInfo() {
  const { accessToken, user } = loadAuthInfo();
  if (accessToken && !isEmpty(user)) {
    store.dispatch(updateUserData({ user }));
    return {
      isAuthenticated: true,
      userRole: user.role,
      userId: user._id,
      provider: user?.businessLine?.key ?? '',
      email: user.email,
    };
  }
  return {
    isAuthenticated: false,
    userRole: '',
    userId: '',
    provider: '',
    email: '',
  };
}

export const doLogin = async (loginData) => {
  return axiosAuthInstance
    .post(`users/login`, loginData)
    .then((res) => {
      if (res.data) {
        saveAuthInfo(res.data.token, res.data.user);
        return res.data;
      }
    })
    .catch((err) => err.response?.data);
};

export const doRegister = async (signUpData) => {
  return axiosAuthInstance
    .post(`users/register`, signUpData)
    .then((res) => res.data)
    .catch((err) => err.response?.data);
};

export const validateZipCode = async (country, zipCode) => {
  return axiosAuthInstance
    .get(`countries/${country}/zip-codes/${zipCode}`)
    .then((res) => res.data)
    .catch((err) => err?.response?.data);
};

export const verifyOtp = async (data) => {
  return axiosAuthInstance.post(`users/verify-otp`, data).then((res) => {
    if (res.data) {
      saveAuthInfo(res.data.token, res.data.user);
      return res.data;
    }
  });
};

export const resendOtp = async (email) => {
  return axiosAuthInstance
    .get(`users/resend-otp/${email}`)
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

export const forgetPassword = async (email) => {
  return axiosAuthInstance
    .get(`users/forget-password/${email}`)
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

export const validateToken = async (token) => {
  return axiosAuthInstance
    .get(`users/validate-token/${token}`)
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

export const updatePassword = async (data) => {
  return axiosAuthInstance
    .patch(`users/update-password`, data)
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

export const getAllBusinessLines = async () =>
  await axiosAuthInstance
    .get('business-line/all')
    .then((res) => res.data)
    .catch((err) => err.response.data);

export const doLogout = async (loginData) => {
  return axiosAuthInstance
    .post(`users/logout`, loginData)
    .then((res) => {
      if (res.data) {
        clearAuthInfo();
        return res.data;
      }
    })
    .catch((err) => err);
};
